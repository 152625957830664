<template>
  <div class='safetyDeviceVidicon'>
    <div class="">
      <div class="title_name">
        <div>设备总数：<span class="fs16 col00ffe">{{ total }}</span></div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:device:vidicon:add')"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
          <el-button type="text" @click="openDio('refresh')" v-if="isAdmin"><i class="el-icon-refresh"></i>同步数据</el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:safety:device:vidicon:del')"><i class="el-icon-delete"></i>批量删除</el-button>
        </div>
      </div>
      <div :style="{height:tableH+'px',overflow:'auto'}">
      <el-checkbox-group v-model="tableActive">
      <div class="pageList">
        <div class="item" v-for="(item,index) in tableData" :key="index">
          <el-checkbox :label="item.id"></el-checkbox>
          <div class="liStatus tr">
            <span class="status success" v-if="item.status=='1'">在线</span>
            <span class="status info" v-else>离线</span>
          </div>
          <div class="liCont">
            <el-image fit="contain" :src="getIcon(item.deviceTag)"></el-image>
            <div class="liRight">
              <!-- <p :title="item.deviceSerial">{{item.deviceSerial}}</p> -->
              <p class="name" :title="item.deviceName">{{item.deviceName}}</p>
              <p>{{item.homeName}}</p>
              <p>设备类型:监控设备</p>
              <p :title="item.installPosition">{{item.installPosition}}</p>
              <!-- <p :title="item.validateCode">验证码:{{ item.validateCode }}</p>
              <p :title="item.deviceModel">设备型号:{{ item.deviceModel }}</p>
              <p :title="item.deviceModel">平台类型:{{ item.platformType|statesFilter('1') }}</p> -->
              <!-- <p :title="item.deviceVersion">版本号:{{ item.deviceVersion }}</p> -->
            </div>
          </div>
          <div class="liBtn">
            <div class="btn el-icon-edit" v-if="isAuth('safe:safety:device:vidicon:edit')" title="编辑" @click="openDio('edit',item)"></div>
            <div class="btn el-icon-s-operation" v-if="isAuth('safe:safety:device:vidicon:channel')" title="通道" @click="openDio('channel',item)"></div>
            <div class="btn el-icon-delete" v-if="isAuth('safe:safety:device:vidicon:del')" title="删除" @click="openDio('del',item)"></div>
          </div>
        </div>
      </div>
      </el-checkbox-group>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[12, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <vidicon-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></vidicon-add-edit>
    <!--创建、修改-->
    <vidicon-channel ref="channelDio" :dicObj="dicObj" @addEditSucc="getList()"></vidicon-channel>

  </div>
</template>
<script>
import vidiconAddEdit from './vidicon-add-edit'
import vidiconChannel from './vidicon-channel'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
let d_icon1 = require('@/assets/images/ban_qiu.png');
let d_icon2 = require('@/assets/images/ipc.png');
var vm;
export default {
  props:['dicObj','keyWord'],
  name:'safety-device-vidicon',
  data() {
    return {
      //分页
      curr :1,//当前页
      limits :12,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    vidiconAddEdit,
    vidiconChannel,
  },
  watch:{
    '$store.state.wsMsg':function(newVal,oldVal) {
      this.getSkMsg(newVal)
    },
  },
  async created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
  },
  mounted(){
    window.onresize=() => {
      vm.initHeight()
    }
  },
  filters: {
    //状态过滤器
    statesFilter(val,type){
      //平台类型
      if(type=='1'){
        let result = vm.dicObj.platformData.filter(item => item.dictValue == val)
        return result[0]?.dictName||'-'
      }
    },
  },
  //方法
  methods:{
    getIcon(type){
      if(type=='1'){
        return d_icon1
      }else{
        return d_icon2
      }
    },
    //获取ws数据
    getSkMsg(res){
      //判断是设备状态通知(上、下线)
      if(res?.type=='deviceStatus'){
        let str = vm.tableData?.find(item => item.id == res.data.deviceData?.deviceId)
        if(str){
          str.status = res.data.deviceData?.deviceStatus
        }
      }
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteDeviceMedia(arr)
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增或修改
        }else if(type == 'channel'){
          vm.$refs.channelDio.init(type,item)
        }else if(type=='refresh'){//数据同步
          vm.cameraDataSync()
        }
      }
    },
    //同步
    async cameraDataSync(){
      const res = await apiUrl.dataSync()
      if(res?.code==200){
        vm.$message.success('同步成功')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        vm.deleteDeviceMedia(vm.tableActive);//删除
      }).catch(() => {});
    },
    //删除
    async deleteDeviceMedia(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteDeviceMedia(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤标签
    formatTag (row, column){
      let result = vm.dicObj.tagData.filter(item => item.dictValue == row.deviceTag)
      return result[0]?.dictName||'-'
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      vm.tableActive = []
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':vm.$store.state.homeId,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits,
        ...vm.keyWord
      }
      const res = await apiUrl.deviceMediaPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-350
    }
  }
}
</script>
<style lang='scss'>
.safetyDeviceVidicon{
  
}
</style>
